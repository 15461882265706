<template>
  <div class="listing--form-container">
    <facilities-section ref="facilitiesSection" />
    <other-facilities-section ref="otherFacilitiesSection" />
    <div class="form--group">
      <div class="left--col">
        <label for="furnishing-cond-field">
          <span>{{ $t('addListing.furnishingCondition') }}</span>
        </label>
      </div>
      <div class="right--col">
        <multiselect
          id="furnishing-cond-field"
          name="furnishing_condition"
          v-model="selectedFurnishCond"
          :options="furnishingConditionList"
          track-by="id"
          :custom-label="furnishCondLabel"
          :searchable="false"
          :allowEmpty="true"
          :close-on-select="true"
          :placeholder="$t('addListing.placeholder.furnishingCondition')"
        >
        </multiselect>
      </div>
    </div>
    <operational-hour-section ref="operationalHourSection" />
    <div class="bottom--container">
      <button class="btn btn-primary--outline bottom--button" @click="back">
        {{ $t('addListing.btn.back') }}
      </button>
      <button class="btn btn-primary--outline bottom--button" @click="saveDraft">
        {{ isPublished ? $t('addListing.btn.save') : $t('addListing.btn.saveToDraft') }}
      </button>
      <button v-show="!isPublished" class="btn btn-primary bottom--button" @click="publish">
        {{ $t('addListing.btn.publish') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
const FacilitiesSection = () =>
  import('@/components/listing-form/sect2/facility/facilities-section.vue');

const OtherFacilitiesSection = () =>
  import('@/components/listing-form/sect2/other-facilities/other-faciltiies-section.vue');

const OperationalHourSection = () =>
  import('@/components/listing-form/sect2/operational-hour/operational-hour-section.vue');
export default {
  name: 'sect2-listing-form',
  mixins: [HelperMixin],
  components: {
    FacilitiesSection,
    OtherFacilitiesSection,
    OperationalHourSection,
    Multiselect,
  },
  data() {
    return {
      checkBankAccountDone: false,
    };
  },

  // validators: {
  //   dummyData(value) {
  //     return Validator.value(value)
  //       .required(this.$i18n.t('errors.loginRegister.password.required'))
  //       .minLength(8, this.$i18n.t('errors.loginRegister.password.min', { maxChar: 8 }))
  //       .maxLength(255, this.$i18n.t('errors.loginRegister.password.max', { maxChar: 255 }));
  //   },
  // },
  computed: {
    ...mapState({
      sect2Touched: state => state.v2.listingForm.sect2.isTouched,
      isPublished: state => state.v2.listingForm.isPublished,
      furnishingConditionList: state => state.v2.listingForm.sect2.detail.furnishingConditionList,
    }),
    selectedPropertyTypeCategory() {
      return this.$store.getters['v2/listingForm/sect1/location/selectedPropertyTypeCategory'];
    },
    currentStep: {
      get() {
        return this.$store.state.v2.listingForm.formCurrentStep;
      },
      set(value) {
        this.$store.commit('v2/listingForm/SET_FORM_CURRENT_STEP', value);
      },
    },
    selectedFurnishCond: {
      get() {
        return this.$store.state.v2.listingForm.sect2.detail.selectedFurnishCond;
      },
      set(value) {
        this.$store.commit('v2/listingForm/sect2/detail/SET_SELECTED_FURNISH_COND', value);
      },
    },
  },
  watch: {
    checkBankAccountDone(val) {
      if (val) {
        this.publish();
      }
    },
  },
  methods: {
    furnishCondLabel(data) {
      if (this.selectedPropertyTypeCategory === 1) {
        return data.COMMERCIAL;
      } else if (this.selectedPropertyTypeCategory === 2) {
        return data.RESIDENTIAL;
      } else {
        return '';
      }
    },
    async saveDraft() {
      // if (this.isPublished) {
      //   console.log('masuk sini dong');
      //   const success = await this.validateThenPost('detailForm');
      //   if (success) {
      //     this.$store.commit('v2/listingForm/OPEN_COMPLETE_FORM_MODAL', 'SAVE');
      //     this.$modal.show('form_complete_modal');
      //   }
      // } else {
      try {
        this.setLoading(true);

        const postDataSuccess = await this.$store.dispatch('v2/listingForm/saveDraft');
        if (postDataSuccess) {
          this.$store.commit('v2/listingForm/OPEN_COMPLETE_FORM_MODAL', 'SAVE');
          this.$modal.show('modal-success-listing-form');
        }
      } finally {
        this.setLoading(false);
      }
      // }
    },
    setLoading(value) {
      this.$store.commit('v2/listingForm/SET_LOADING', value);
    },
    validate() {
      let validationArray = [];
      validationArray.push(this.$refs.facilitiesSection.validate());
      validationArray.push(this.$refs.otherFacilitiesSection.validate());
      return Promise.all(validationArray).then(function(results) {
        if (
          results.filter(function(result) {
            console.log('mana yg salah', result);
            return !result;
          }).length === 0
        ) {
          console.log('trueeeee');
          return true;
        }
        console.log('falseeee');
        return false;
      });
    },
    async publish() {
      try {
        this.setLoading(true);
        const isValid = await this.validate();
        if (isValid) {
          if (!this.checkBankAccountDone) {
            const resultCheck = await this.$store.dispatch('v2/profile/checkUserCanAcceptBooking');
            if (!resultCheck) {
              this.$store.commit('v2/listingForm/OPEN_COMPLETE_FORM_MODAL', 'PUBLISH');
              this.$modal.show('modal-input-bank-account');
              return false;
            } else {
              this.$store.commit('v2/listingForm/SET_IS_PUBLISHED', true);
              if (isValid) {
                const postDataSuccess = await this.$store.dispatch('v2/listingForm/postData');
                if (postDataSuccess) {
                  this.$store.commit('v2/listingForm/OPEN_COMPLETE_FORM_MODAL', 'PUBLISH');
                  this.$modal.show('modal-success-listing-form');
                }
              } else {
                this.scrollToErrorSection(this.$el);
                return false;
              }
            }
          } else {
            this.$store.commit('v2/listingForm/SET_IS_PUBLISHED', true);
            if (isValid) {
              const postDataSuccess = await this.$store.dispatch('v2/listingForm/postData');
              if (postDataSuccess) {
                this.$store.commit('v2/listingForm/OPEN_COMPLETE_FORM_MODAL', 'PUBLISH');
                this.$modal.show('modal-success-listing-form');
              }
            } else {
              this.scrollToErrorSection(this.$el);
              return false;
            }
          }
        } else {
          this.scrollToErrorSection(this.$el);
          return false;
        }
      } finally {
        this.setLoading(false);
      }
    },
    async back() {
      const postDataSuccess = await this.$store.dispatch('v2/listingForm/saveDraft');
      if (postDataSuccess) {
        this.currentStep = this.currentStep - 1;
      }
      // if (await this.save()) {
      //   this.currentStep = this.currentStep - 1;
      // }
    },
  },
};
</script>

<style scoped></style>
